export function Headline() {
  return (
    <div className="description__headline">
      Hey, I'm{" "}
      <b>
        <i>Irina</i>
      </b>
      ! 👋🏼
    </div>
  );
}
